const toggleMenu = () => {
    const openMenu = document.querySelector(".bll-open");
    const closeMenu = document.querySelector(".bll-close");
    const navbarNav = document.querySelector(".navbar-nav");

    openMenu.addEventListener("click", () => {
        openMenu.classList.toggle("bll-back");
        closeMenu.classList.toggle("bll-front");
        navbarNav.classList.toggle("show");
    });
    closeMenu.addEventListener("click", () => {
        closeMenu.classList.toggle("bll-front");
        openMenu.classList.toggle("bll-back");
        navbarNav.classList.toggle("show");
    });
};

toggleMenu();