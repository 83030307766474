// class au scroll
const siteHeader = document.querySelector('.site-header');

window.addEventListener('scroll', () => {
    if (window.scrollY > 10) {
        siteHeader.classList.add('is-scrolled');
    } else {
        siteHeader.classList.remove('is-scrolled');
    }
});

// animation des chiffres
const options = {
    threshold: 0.5, // déclenche l'observateur lorsque la moitié de la section est visible
};

const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) { // la section est visible à l'écran
            const numberSpans = entry.target.querySelectorAll('.number');
            const finalNumbers = Array.from(numberSpans).map((numberSpan) => parseInt(numberSpan.textContent));
            const initialNumbers = Array.from(numberSpans).map(() => 0);

            let elapsed = 0;
            const intervalId = setInterval(() => {
                elapsed += 10;
                const progress = Math.min(1, elapsed / 3000); // 3000 ms = 3 s
                const currentNumbers = initialNumbers.map(
                    (initialNumber, i) =>
                    Math.floor(initialNumber + (finalNumbers[i] - initialNumber) * progress)
                );

                numberSpans.forEach((numberSpan, i) => {
                    const currentNumber = currentNumbers[i];
                    numberSpan.textContent = currentNumber.toString().padStart(2, '0');
                });

            if (progress >= 1) {
                clearInterval(intervalId);
            }
            }, 10);

            observer.unobserve(entry.target); // arrête l'observation une fois que le compteur est terminé
        }
    });
}, options);

const sections = document.querySelectorAll('.section');
sections.forEach((section) => {
    observer.observe(section); // commence l'observation de chaque section
});

// animation des cartes visas
const imgContainer = document.querySelector('.accueil .container-img-right');
if (imgContainer) {
    const img = imgContainer.querySelector('img');

    img.style.transition = 'transform 1s ease-in-out';
    img.style.transformOrigin = 'center center';

    let rotate = 0;
    let translateY = 0;
    let translateX = 0;
    let isReversing = false;
    let verticalOffset = 0;
    let isMovingUp = true;

    function animate()
    {
        rotate += isReversing ? -0.1 : 0.1;
        verticalOffset += isMovingUp ? 1.5 : -1.5;
        if (verticalOffset >= 150 || verticalOffset <= -150) {
            isMovingUp = !isMovingUp;
        }
        translateY = Math.sin(rotate) * 25 + verticalOffset;
        translateX = Math.cos(rotate) * 15;

        if (rotate >= 30 || rotate <= -30) {
            isReversing = !isReversing;
            img.style.transition = 'transform 1s ease-in-out';
        }

        img.style.transform = `translate(${translateX}px, ${translateY}px) rotate(${rotate}deg)`;

        requestAnimationFrame(animate);
    }

    animate();
}

// Set the time zone for Andorra
Intl.DateTimeFormat('default', { timeZone: 'Europe/Andorra' });

// Define the function to update the time
function updateClock()
{
    // Get the current time
    const time = new Date().toLocaleTimeString('default', { timeZone: 'Europe/Andorra' });

    // Display the time with automatic scrolling
    document.getElementById('clock').textContent = time;
    const secCounter = document.querySelector('#clock_contact');
    if (secCounter) {
        document.getElementById('clock_contact').textContent = time;
    }
}

// Call the function to update the time every second
setInterval(updateClock, 1000);

//resize bloc meme hauteur
function setEqualHeight()
{
    const keyDiv = document.querySelector('.accueil .key');
    const panelRightDiv = document.querySelector('.accueil .panel-right');

    if (keyDiv && panelRightDiv) {
        const keyHeight = keyDiv.offsetHeight;
        const panelRightHeight = panelRightDiv.offsetHeight;
        const maxHeight = Math.max(keyHeight, panelRightHeight);

        keyDiv.style.height = `${maxHeight}px`;
        panelRightDiv.style.height = `${maxHeight}px`;
    }
}

window.addEventListener('resize', setEqualHeight);
setEqualHeight();

const parentDivs = document.querySelectorAll('.btn-split');
if (parentDivs.length) {
    parentDivs.forEach(parentDiv => {
        const buttons = parentDiv.querySelectorAll('.btn');
        const decoPink = parentDiv.querySelector('.deco-pink');

        const setButtonWidth = () => {
            let maxWidth = 0;
            buttons.forEach(button => {
                const buttonWidth = button.offsetWidth;
                if (buttonWidth > maxWidth) {
                    maxWidth = buttonWidth;
                }
            });
            const spaceWidth = Math.max(100, parentDiv.offsetWidth - (maxWidth * 2));
            const decoPinkWidth = spaceWidth;
            const totalWidth = (maxWidth * 2) + decoPinkWidth;
            buttons.forEach(button => {
                button.style.width = maxWidth + 'px';
            });
            decoPink.style.width = decoPinkWidth + 'px';
            parentDiv.style.width = Math.min(totalWidth, 900) + 'px';
        };

        window.addEventListener('resize', setButtonWidth);
        setButtonWidth();
    });
}

// redirection apres envoi mail
document.addEventListener('wpcf7mailsent', function (event) {
    var countDown = 5; // définir le compte à rebours en secondes
    var redirectUrl = window.location.protocol + '//' + window.location.hostname;

    // vérifier si le formulaire est envoyé depuis une modale Bootstrap 5
    var isModal = false;
    var form = event.detail.contactForm;
    if (form && form.parentElement && form.parentElement.classList.contains('modal-body')) {
        isModal = true;
    }

    // créer la structure HTML pour afficher le message et le compte à rebours à l'écran
    var redirectDiv = document.createElement('div');
    redirectDiv.classList.add('redirect');
    var elemDiv = document.createElement('div');
    elemDiv.classList.add('elem');
    var messageP = document.createElement('p');
    messageP.textContent = 'Your message has been received.';
    elemDiv.appendChild(messageP);
    redirectDiv.appendChild(elemDiv);

    // afficher le message dans la modale ou sur la page
    if (isModal) {
        var modalBody = form.parentElement;
        if (modalBody) {
            modalBody.appendChild(redirectDiv);
            setTimeout(function () {
                modalBody.parentElement.classList.remove('show');
                modalBody.parentElement.removeAttribute('style');
                modalBody.parentElement.setAttribute('aria-hidden', 'true');
                modalBody.parentElement.setAttribute('aria-modal', 'false');
                modalBody.parentElement.setAttribute('tabindex', '-1');
                modalBody.classList.remove('show');
                modalBody.removeAttribute('style');
                modalBody.dispatchEvent(new Event('hidden.bs.modal'));
            }, 3000);
        } else {
            console.error('Unable to find modal body element.');
        }
    } else {
        var contactPage = document.querySelector('.page-template-contact-page');
        if (contactPage) {
            contactPage.appendChild(redirectDiv);

            // afficher le compte à rebours à l'écran
            var numbersSpan = document.createElement('span');
            numbersSpan.classList.add('numbers');
            elemDiv.appendChild(numbersSpan);
            var timer = setInterval(function () {
                if (countDown <= 0) {
                    clearInterval(timer);
                    window.location.href = redirectUrl; // rediriger vers la page d'accueil de WordPress
                } else {
                    numbersSpan.innerHTML = countDown;
                    countDown--;
                }
            }, 1000);
        } else {
            console.error('Unable to find contact page element.');
        }
    }
}, false);

//gestion des hauteurs pages de liste
const adjustHeight = () => {
    const results = document.getElementsByClassName("content-result");
    let maxHeight = 0;

    // Trouver la hauteur maximale de tous les éléments avec la classe "content-result"
    for (let i = 0; i < results.length; i++) {
        results[i].style.height = "auto";
        if (results[i].offsetHeight > maxHeight) {
            maxHeight = results[i].offsetHeight;
        }
    }

    // Appliquer la hauteur maximale à tous les éléments avec la classe "content-result"
    for (let i = 0; i < results.length; i++) {
        results[i].style.height = maxHeight + "px";
        const btns = results[i].getElementsByClassName("btn-dark");
        if (btns.length > 0) {
            for (let j = 0; j < btns.length; j++) {
                const btnHeight = btns[j].offsetHeight;
                btns[j].style.position = "absolute";
                btns[j].style.bottom = "30px";
                btns[j].style.right = "30px";
                btns[j].style.margin = "auto";
                results[i].style.paddingBottom = `${btnHeight}px`;
            }
        }
    }
};

// Appeler la fonction une première fois
adjustHeight();

// Ajouter un event listener pour redimensionner l'élément lorsqu'on change la taille de la fenêtre
window.addEventListener("resize", adjustHeight);

//load more
const loadMoreBtn = document.querySelector('.load-more');
const colsContainer = document.querySelector('.for-loading');
if (colsContainer) {
    const cols = document.querySelectorAll('.for-loading .col');
    const numColsToShow = 6;
    let numColsShowing = numColsToShow;

// Masquer toutes les colonnes sauf les premières
    for (let i = numColsToShow; i < cols.length; i++) {
        cols[i].style.display = 'none';
    }

// Vérifier le nombre total de colonnes et masquer le bouton "load more" s'il n'y en a pas assez
    if (cols.length <= numColsToShow) {
        loadMoreBtn.style.display = 'none';
    }

    loadMoreBtn.addEventListener('click', () => {
        const numColsToHide = numColsShowing;
        numColsShowing += numColsToShow;

        // Afficher les colonnes suivantes en douceur
        for (let i = numColsToHide; i < numColsShowing && i < cols.length; i++) {
            cols[i].style.display = 'block';
            cols[i].classList.add('showing');
        }

        // Si toutes les colonnes sont affichées, masquer le bouton "load more"
        if (numColsShowing >= cols.length) {
            loadMoreBtn.style.display = 'none';
        }

        // Scroller vers la première colonne affichée
        const firstShownCol = colsContainer.querySelector('.col:not([style*="display: none"])');
        if (firstShownCol) {
            colsContainer.scroll({
                behavior: 'smooth',
                left: firstShownCol.offsetLeft,
            });
        }
    });

// Ajouter un écouteur d'événement de transition pour masquer les colonnes précédemment affichées
    cols.forEach((col) => {
        col.addEventListener('transitionend', () => {
            if (!col.classList.contains('showing')) {
                col.style.display = 'none';
            }
            col.classList.remove('showing');
        });
    });
}

//recherche

const searchBtn = document.querySelector('.search-btn');
const innerSearch = document.querySelector('.inner-search');

searchBtn.addEventListener('click', () => {
    innerSearch.classList.toggle('active-search');
    searchBtn.classList.toggle('is-active');
});




