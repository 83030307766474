import Glide from '@glidejs/glide';

const actuSlider = document.querySelector('.actu-slider');

if (actuSlider) {
    const glide = new Glide(actuSlider, {
        type: "carousel",
        touchAngle: 45,
        focusAt: 1,
        startAt: 1,
        perView: 3,
        keyboard: true,
        gap: 10,
        cloneSlide: 'col',
        activeSlide: 'glide__slide--active',
        autoplay: 2000,
        breakpoints: {
            1024: {
                perView: 2
            },
            600: {
                perView: 1
            }
        }

        })
    glide.mount()
}

const teamSlider = document.querySelector('.team-slider');

if (teamSlider) {
    const glide = new Glide(teamSlider, {
        type: "carousel",
        touchAngle: 45,
        focusAt: 1,
        startAt: 1,
        perView: 4,
        keyboard: true,
        gap: 10,
        cloneSlide: 'col',
        activeSlide: 'glide__slide--active',
        autoplay: 2000,
        breakpoints: {
            1285: {
                perView: 3
            },
            768: {
                perView: 2
            },
            680: {
                perView: 1
            }
        }

    })
    glide.mount()
}
